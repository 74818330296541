
html {
  -webkit-font-smoothing: antialiased;
}

body {  
  font-family: Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;  
}

