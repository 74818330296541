$bgcolor: #fff;
$main-color: #000;
$second-color: #f1fb9a;
$second-color: #f9ef98;
$hard-yellow: #E1FF32;
$highlight: #000;
$dark-grey: #777777;

$main-font: 'Cabin Condensed', sans-serif;
$header-font:'Cabin Condensed', sans-serif;

$max-width: 1200px;
$mobile-breakpoint-width: 960px;
 