/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: $bgcolor;
  color: $main-color;
  padding: 0;
}

a {
  color: $main-color;
}

ul,
li {
  margin:0;
  padding:0;
}

nav li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.active, a:hover{
  color: $highlight;
}

