/* Put your design here */

body, html {
	height: 100%;
	font-size: 18px;
	font-family: $main-font;
	font-weight: 600;
}
p {
	font-weight: 600;
}


.paddingBlock {
	padding: 20px 0;
}

.eqWrap {
	display: flex;
}

.eq {
	padding: 10px;
}
.equalHMWrap {
	justify-content: space-between;
}
#container {
//	display: flex;
//	flex-direction: column;
	height: 100%;
	// max-width: $max-width;
	margin: 0 auto;
}
.tiny {
	font-size:12px;
	line-height: 14px
}
main.main {
	flex: 1 0 auto;
}
.btn {
	font-size: 18px;
	padding: 16px 50px;
	color: #FFF;
	margin: 0;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	background-color: rgba($second-color, 0.2);
	background: transparent;
	border: 1px solid $second-color;
}
.btn.black {
	background-color: transparent;
	border: 1px solid $second-color;
	color: $second-color;
}
.btn:hover {
	background-color: rgba($second-color, 0.1);
	border-color: #FFF;
}
.btn.black:hover {
	background-color: rgba($second-color, 0.12);
	border: 1px solid $second-color;
}

.content-block {
	margin-top: 105px;
	text-align: center;
	padding: 0px;
	&.no-top-margin {
		margin-top:0;
	}
	&.event-block  {
		background: #000;
		padding-bottom: 100px;
	}
	.header-cover {
		text-align: center;
		width: 100%;
		background: #000;
		background-color: rgba($main-color,0.4);
	}
	.header-cover img {
		text-align: center;
		display: inline;
		width: 100%;
	}
	.header-cover-bg {
		clear: both;
		background: transparent center bottom no-repeat;
		background-size: cover;
		background-image:url('/images/timo.jpg');
		position: relative;
		height: 100%;
		height: 600px;
		text-align: center;
		width: 100%;
		border: 1px solid #1b1b1b;
	}

	.header-text.first h2 {
		font-family: $header-font;
		font-size: 45px;
		line-height: 45px;
		font-weight: 400;
		margin: 10px 0;
	}
	.header-text.second {
		clear:both;
		color: #fff;
		padding: 30px 0 0 0;
		font-family: $main-font;
		vertical-align: middle;
		// background-color: $main-color;
		text-align: center;
		width: 60%;
		margin: 30px auto;

	}

	.body-text {
		border-top:1px solid rgba(#fff,0.2);
		text-align: left;
		padding: 30px 0;
		color: #fff;
		width: 70%;
		margin:0 auto;

	}
  .columns {
			column-count: 2;
	}
	.note {
		margin-top: 50px;
		margin-bottom: 20px;
		max-width: 500px;
		text-align: left;
	}
	.footer-text {
		text-align: center;
	}

}
.footer {
	background-color: #fff;
	color: #000;
	text-align: center;
	width: 100%;
	min-height: 150px;
	.newsletter {
		width: 320px;
		text-align: center;
		margin: 35px auto;
		padding: 35px 0;
	}
	input {
		padding: 10px;
		line-height: 45px;
		color: #FFF;
		float: left;
		border:1px solid #FFF;
		margin:5px 0;
		border-radius:0;
	}
	#mc_embed_signup {
		clear: both;
		text-align: center;
		margin: 30px auto;
	}
	input.button {
		background: darken($main-color, 10%);
		border:1px solid #FFF;
		text-align: center;
	}

	.disclaimer {
		clear: both;
		text-align: center;
		font-size: 15px;
		margin-top: 100px;
		padding: 0 30px;
	}
}


/* ---------------- HOMEPAGE ------------- */
.intro-homepage {
	width: 50%;
	margin-left: 50%;
	font-size: 30px;
	line-height: 35px;
	color: #000;
	padding: 150px 30px;
}

/* ---------------- EVENT OVERVIEW ------------- */
.content-block-container {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-bottom: 100px;
	margin-top: 60px !important;
}
.events-overview {
	display: flex;
	.event-cover {
		text-decoration: none;
		text-align: left;
		flex: 1 1 0;
		padding: 20px;
		padding-top: 40px;
		padding-bottom: 40px;
		.more {
			color: $dark-grey;
		}
		img {
			width: 100%;
		}
		h2 {
			margin-bottom: 5px;
		}
		&:hover {
			background: rgba(200,200,200,0.1);
		}
	}
}

/* ---------------- EVENT ------------- */
.event-header-container {
	text-align: right;
	a {
		text-decoration: none;
	}
}
.detail .event-header-container {
	margin-top: 200px;
}
.event-header {
	border-top: 1px solid #ccc;
	padding: 50px 30px;
	background-color: #FFF;
	text-align: left;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 auto;
	cursor:pointer;


	.column {
		display: flex;
   		flex-direction: column;
   		flex-basis: 100%;
   		flex: 1;
	}
	.details {
		flex-basis: 50%;
		float: left;
		padding-top: 75px;
		font-size: 16px;
		padding-left: 30px;

	}
	.subject {
		flex-basis: 50%;
		text-align: left;
		h2 {
			padding-top: 0;
			margin-top: 0;
			margin-bottom: 10px;
			font-size: 55px;
			line-height: 35px;
			font-weight: bold;
		}
		.subject-details {
			font-size: 18px;
			line-height: 17px;
			margin-top: 40px;
			color: #aaa;
			font-weight: 400;
		}
	}

}


@media only screen and (max-width: $mobile-breakpoint-width) {



	/* COVER HEADER */
	.intro-homepage {
		width: 100%;
		font-size: 23px;
		line-height: 28px;
		margin-left: 0;
	}

	.event-header {
		width: 100%;
	}
	.content-block {
		.body-text,
		.header-text.second {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
		}

	}
}


@media only screen and (max-width: 650px) {

	.events-overview {
		display: block;
		margin-top: 40px;
		border:0;
		.event-cover {
			display: block;
			margin-bottom: 20px;
			border: 0;
			padding: 20px;
		}
	}
	.columns,
	.content-block .columns {
			column-count: 1;
	}


		.event-header {
			.details {
				padding-top: 25px;
			}
			.details,
			.subject {
				flex-basis: 100%;
				padding-left: 0;
				h2 {
					margin: 10px 0;
					font-size: 45px;
				}
				.subject-details {
					margin-left: 0px;
				}
			}

			.subject .arrow {
				display: none;
			}

		}




	}
