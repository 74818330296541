
.header {
  position: relative;
  padding: 60px 30px;
  background:#FFF;
/*  position: fixed;
  top: 0;
  right:0px;
  left: 0px; */
  z-index: 200;
}
.header a {
  text-decoration: none;
  font-weight: bold;
}
/* MENU */
.inner {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
}
// inner - right-nav (main-nav)
.project-logo {
  font-weight: bold;
  padding: 10px 0;
  text-decoration: none;
 // font-family: $header-font;
}

.main-nav ul {
  display: flex;
  li {
    padding: 10px 5px;
    font-size:22px;
  }
  li a {
    padding: 0 25px;
  }
}

.right-nav {
  width: 50%;
}
/* MOBILE MENU */
.m-menu {
  position: absolute;
  top:0;
  right:0;
  display: none;

  .mm {
    border:none;
    background:none;
    cursor: pointer;
    position: relative;
    display: block;
    width:50px;
    height:50px;
    outline:none;
    padding: 0; // ios has a bug otherwise
    margin-top: 60px;
    &:before, &:after {
      position: absolute;
      display: block;
      content:'';
      width:14px;
      height: 2px;
      left: 18px;
      top: 19px;
      background-color:$main-color;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:after {
      top: 27px;
    }
  }
}

.m-menu-open {
  .m-menu .mm {
    &:before {
      top:24px;
      transform:rotate(-45deg);
    }
    &:after {
      top:24px;
      transform:rotate(45deg);
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint-width) {
  body {
      // background-color: red;
  }
  .m-menu {
    display: block;
  }

  html.noscroll {
    overflow: hidden;
  }

  body.noscroll {
    overflow: visible;
    height: 100%;
  }

  .m-menu-open {
    .main-nav {
      position: fixed;
      z-index: 9999;
      display: block;
      left: 0;
      bottom:0;
      right:0;
      top: 164px;
      width: 100%;
      background-color: #FFF;
      color: #000;
      ul {
        flex-direction: column;
        margin-top: 40px;
        li {
          margin:0;
          padding:0;
        }
        a {
          display: block;
          text-align: center;
          font-size: 24px;
          padding-top: 45px;
          padding-bottom: 45px;
          margin:0;
          border-bottom:1px solid rgba($main-color, 0.2)
        }
        a:hover {
          color: #000;
          background: #EFEFEF;
        }
      }
    }
  }

  .main-nav {
    display: none;
  }

}
